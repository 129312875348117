/*
  NancyGrid CSS file used to style the basic grid components
  NB: The styles responsible for the grid layout itself are
      kept inline in the React code (NancyGrid.js). These
      styles are the styles wrapping the 
  Areas:
    - Header: A <header> tag containing the header occupying the top of the vierwport. (A React <AppBar>)
    - Sidebar: An <aside> tag occupyint the left side of the content pane (between the header and footer)
    - Content pane: A <main> tag occupyint most of the page
    - Footer: A <footer> tag occupyint the bottom of the viewport

  IMPORTANT:
    - Only add structural styles to this css files (like margin and padding).
    - Theme specific styles (colors) SHOULD be defined in the theme files.
*/

:root {
    padding: 0;
    margin: 0;
    /* Variables: Defined as "--var-name: value;" where value can be 50px. */
}

header.NancyHeader {
    margin: 0;
    padding: 0;
}


/* ************************************************************************* */
/* <Sidebar>  */
/* ************************************************************************* */
/* The <aside> tag that is top level element of the <Sidebar> component */ 
/* Style the scrollbar for the sidebar */
div.NancySidebarContainer::-webkit-scrollbar {
    width: 3px;
}

div.NancySidebarContainer::-webkit-scrollbar-track {
    background-color: transparent;
}
div.NancySidebarContainer::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}
aside.NancySidebar {
    margin: 0;
    padding: 0;
    height: 100%;
}
/*  */ 
aside.NancySidebar .NancySidebarItems {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
aside.NancySidebar .SidebarListItemButton {
    margin: 0;
    padding: 1px 5px 1px 15px;
}
aside.NancySidebar #NotesListContainer .SidebarListItemButton {
    padding: 1px 5px 1px 15px;
}

/*  */ 
aside.NancySidebar .navLink {
    display: block;
    padding: 0;
}


@media (min-width: 3500px) {
    :root {
      --sidebar-width: 700px;
    }
}

@media (min-width: 2500px) and (max-width: 3499px) {
    :root {
      --sidebar-width: 600px;
    }
}

@media (min-width: 1500px) and (max-width: 2499px) {
    :root {
      --sidebar-width: 500px;
    }
}
  
@media ( max-width: 1499px) {
    :root {
      --sidebar-width: 400px;
    }
}


/* ************************************************************************* */
/* </Sidebar> styles end */
/* ************************************************************************* */

main.NancyContentPane {
    /*empty for now*/
}

footer.NancyFooter {
    margin: 0;
    padding:3px 1em 3px 1em;
    vertical-align: middle;
    background: #bbcbe8;
    border-top: 2px solid #434e67;
    color: #434e67;
}
footer.NancyFooter .NancyFooterStatusText {
    font-weight: normal;
    color: #434e67;
}

.NancyIcon {
    margin: 0;
    padding: 0;
    object-fit: contain;
}

