:root {
    --sidebar-bgcolor: #e1effa;
}


.errorBlock {
    background-color: rgb(255, 79, 94);
}

/* ************************************************************************* */
/* <NancyHeader>                                                             */
/* ************************************************************************* */
.NancyToolbar {
    background-color: #4777A3;
    /*
    4A5D6E steele blue
    e4dcd7 light brown
    7e706b darker brown
    3d3b3c dark brown
    766865
    7f7270
    38353a dark purple
    62696d mid grey
    6a7173 mid green
    c5c5c5
    */
}
header.NancyHeader .NancyHeaderTitle {
    color: #e9f2fa;
    padding-left: 1em;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
}
header.NancyHeader .NancyHeaderTitle span {
    color: #a9b5be;
    font-weight: 100;
}
header.NancyHeader .NancyHeaderTitle sub {
    color: #a9b5be;
    font-weight: 600;
    font-size: 0.4em;
}
/* ************************************************************************* */
/* </NancyHeader>                                                            */
/* ************************************************************************* */



/* ************************************************************************* */
/* <Sidebar>                                                                 */
/* ************************************************************************* */
/* Main component tag styling */
aside.NancySidebar {
    background-color: var(--sidebar-bgcolor);
    /*border-right: 2px solid #b3c8d8;*/
}
/* Styling of the UL containing the LI with links */
aside.NancySidebar .NancySidebarItems {
    background-color: var(--sidebar-bgcolor); /* Repeat sidebar bg color since the sidebar "clips" at "100% height". */
    --no-warning-workaround: "A line just to make vscode not warn about an empty style";
}
/* Styling of the <NavLink> components for each LI */
aside.NancySidebar .navLink {
    text-decoration: none;
    color: #000;
    transition: background-color 0.3s;
}
/* Hover effect for UL LI <NavLink> */
aside.NancySidebar .navLink:hover {
    background-color: #e0e0e0;
}
/* Selected effect for UL LI <NavLink> */  
aside.NancySidebar .SidebarListItemButtonActive {
    background-color: #c6ddee;
    color: #222;
    font-weight: bold !important; /* Not working for unknown reasons */
}
/* ************************************************************************* */
/* </Sidebar> styles end                                                     */
/* ************************************************************************* */





/* ************************************************************************* */
/* <Menu> styles                                                             */
/* ************************************************************************* */
/*
    Hack to give menu items a bit more right-padding and make them look better.
    May break if material-ui changes their class names.
*/
.NancyPopupMenu .MuiMenuItem-root {
    padding-right: 3em;
}

/* ************************************************************************* */
/* </Menu> styles end                                                        */
/* ************************************************************************* */



/* ************************************************************************* */
/* <ContentPane> styles start                                                */
/* ************************************************************************* */
.ContentPaneContainer {
    margin-top: 2em;
}
.NancyNoteContainer {
    border: 1px solid #9aa7b2;
    padding: 1em;
}

/* ************************************************************************* */
/* </ContentPane> styles end                                                 */
/* ************************************************************************* */



/* ************************************************************************* */
/* <QuillEditor> styles start                                                */
/* ************************************************************************* */
.ql-editor{
    min-height:200px;
}
/* ************************************************************************* */
/* </QuillEditor> styles end                                                 */
/* ************************************************************************* */
